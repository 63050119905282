/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  AWS_ACCESS_KEY_ID: '',
  AWS_SECRET_ACCESS_KEY: '',
  production: false,


  e2e: false,
  //redirectionURL: 'https://trainer.my.site.com/trainerconnect/s/course-events',
  // salesforceAuthUrl: 'https://login.salesforce.com/services/oauth2/authorize',
  //salesforceAuthUrl: 'https://trainer--prosandbox.sandbox.my.salesforce.com/services/oauth2/authorize',
  // salesforceAuthUrl: 'https://trainer--qbsfull.sandbox.my.site.com/trainerconnect/services/oauth2/authorize',
  // salesforceLogouturl: 'https://trainer--qbsfull.sandbox.my.site.com/trainerconnect/secur/logout.jsp',
  //salesforceLogouturl: 'https://trainer--prosandbox.sandbox.my.salesforce.com/trainerconnect/secur/logout.jsp',

  salesforceAuthUrl: 'https://trainer--qbsfull.sandbox.my.site.com/trainerconnect/services/oauth2/authorize',
  salesforceLogouturl: 'https://trainer--qbsfull.sandbox.my.site.com/trainerconnect/secur/logout.jsp',
  salesforceUrl: 'https://trainer--qbsfull.sandbox.my.site.com/trainerconnect',
  // salesforceLogouturl: 'https://trainer--prosandbox.sandbox.my.salesforce.com/trainerconnect/secur/logout.jsp',
  // salesforceClientId_old: '3MVG9nfjjfLf_QreRWa3uOeSJ_R.nXEXj11f79eSe6iZv5jB0iW6hsywvazkNSsHdL7zX.Bdp3hcpxb45HB.t',
  salesforceClientId: '3MVG9wLr_6EJB3zCYw7SwN0JQ4jBPhAS2XRzRwZsi2l_d4TjLOJviDWdwux0Z3JxfPekgBIO_z1eaHl1ithsZ',
  salesforceRedirectUrl: 'https://test.trainerconnectonline.com/#/oauth/_callback',
  // salesforceRedirectUrl: 'http://localhost:4200/#/oauth/_callback',

};
